import React from "react"
import Article from "../components/Article"
import i18nHOC from "../components/i18nHOC"
import Paragraph from "../components/Paragraph"
import ImageWithTitle from "../components/ImageWithTitle"
import { graphql } from "gatsby"
import ImageGrid from "../components/ImageGrid"

function oregano({ data, t }) {
  const smallPadding = { padding: "0 5%" }
  const largePadding = { padding: "0 15%" }

  return <Article coverImage={{ image: data.coverImage, title: t("items.oregano.coverCap")}} title={t("items.oregano.title")}>
    <Paragraph>{t("items.oregano.p1")}</Paragraph>
    <Paragraph>{t("items.oregano.p2")}</Paragraph>
    <ImageWithTitle style={smallPadding} fluid={data.oregano2.childImageSharp.fluid} title={t("items.oregano.cap1")}/>
    <ImageWithTitle style={smallPadding} fluid={data.oregano3.childImageSharp.fluid}
                    title={t("items.oregano.cap2")}/>
    <ImageGrid>
      <ImageWithTitle fluid={data.grid1.childImageSharp.fluid} title={t("items.oregano.cap3")}/>
      <ImageWithTitle fluid={data.grid2.childImageSharp.fluid} title={t("items.oregano.cap4")}/>
      <ImageWithTitle fluid={data.grid3.childImageSharp.fluid} title={t("items.oregano.cap5")}/>
      <ImageWithTitle fluid={data.grid4.childImageSharp.fluid}
                      title={t("items.oregano.cap6")}/>
    </ImageGrid>
    <ImageWithTitle style={largePadding} fluid={data.largePadding1.childImageSharp.fluid}
                    title={t("items.oregano.cap7")}/>
    <ImageWithTitle style={largePadding} fluid={data.largePadding2.childImageSharp.fluid}
                    title={t("items.oregano.cap8")}/>
  </Article>
}

export default i18nHOC(oregano);

export const query = graphql`
  query {
    coverImage: file(relativePath: { eq: "oregano-syrup/DSC_2486 (1).JPG" }) {
      ...CoverImage
    },
    oregano2: file(relativePath: { eq: "oregano-syrup/DSC_1842.JPG" }) {
      ...FluidArticleImage
    },
    oregano3: file(relativePath: { eq: "oregano-syrup/IMG_7854 (1).jpg" }) {
      ...FluidArticleImage
    },
    
    grid1: file(relativePath: { eq: "oregano-syrup/DSC_2615.JPG" }) {
      ...FluidArticleImage
    },
    grid2: file(relativePath: { eq: "oregano-syrup/DSC_2623.JPG" }) {
      ...FluidArticleImage
    },
    grid3: file(relativePath: { eq: "oregano-syrup/DSC_2830.JPG" }) {
      ...FluidArticleImage
    },
    grid4: file(relativePath: { eq: "oregano-syrup/DSC07973.JPG" }) {
      ...FluidArticleImage
    },
    
    largePadding1: file(relativePath: { eq: "oregano-syrup/thyme on mtatsminda.JPG" }) {
      ...FluidArticleImage
    },
    largePadding2: file(relativePath: { eq: "oregano-syrup/DSC_9730.JPG" }) {
      ...FluidArticleImage
    },
  }
`